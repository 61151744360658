form
  width: 100%

.form-group
  display: inline-block
  position: relative
  margin: 0 0 10px
  width: 100%
  &-loading
    pointer-events: none
    opacity: 0.7
  &-with-outside-button
    width: calc(100% - 50px)
    position: relative
    > .btn
      height: 42px !important
      width: 42px !important
      position: absolute !important
      right: -50px !important
      top: 4px !important
  &-with-button
    position: relative
    input
      padding-right: 50px !important
      width: 100%
    button, .btn
      position: absolute !important
      bottom: 0
      right: 0
      height: 50px !important
      width: 50px !important
      &:before
        left: 0 !important
        right: 0 !important
        margin: auto !important

input,
textarea
  resize: none

.form-label-highlight
  @include var(color, primary)
  font-size: 16px
  display: inline-block
  height: 16px
  line-height: 16px
  padding-right: 3px

.form-control
  width: 100%
  height: 50px
  display: inline-block
  float: left
  border-radius: $borderRadius
  border: 2px solid $grayColor
  background-color: $whiteColor
  margin: 0
  padding: 10px 15px
  line-height: 20px
  color: $textColor
  box-shadow: none
  font-size: $mainFontSize
  span:not(.invalid-feedback):not([aria-live]):not([class*="ion-"])
    position: relative
    &::before
      position: absolute
      right: -20px
      top: 5px
  &:invalid
    background-color: $errorBackgroundColor !important
  &:focus, &:active
    outline: none !important
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.10)
    @include var(border-color, primary)
  &-placeholder
    user-select: none
  &-empty
    line-height: 25px
  &.is-invalid
    border-color: $grayColor
  &:disabled
    cursor: not-allowed
    pointer-events: none
    background-color: $grayMediumColor
    opacity: 0.7
  &[readonly]
    background-color: $grayMediumColor
    opacity: 0.7
    cursor: default
    &:focus, &:active
      border: 2px solid $grayColor
      box-shadow: none
  &:invalid + span:not(.invalid-feedback):not([aria-live]):not([class*="ion-"])::before
    font-family: 'Ionicons'
    content: "\f2c0"
    color: $orangePrimaryColor
    font-size: 15px
  &:valid + span:not(.invalid-feedback):not([aria-live]):not([class*="ion-"])::before
    font-family: 'Ionicons'
    content: "\f2bc"
    color: $primaryColor
    font-size: 15px
  &.small
    height: 40px
    padding: 5px 15px

*::-webkit-input-placeholder
  color: lighten($textColor, 40) !important

*:-moz-placeholder
  color: lighten($textColor, 40) !important
  opacity: 1

*::-moz-placeholder
  color: lighten($textColor, 40) !important
  opacity: 1

*:-ms-input-placeholder
  color: lighten($textColor, 40) !important

textarea:not(.no-min-height)
  min-height: 150px

.invalid-feedback
  position: relative
  display: block
  float: left
  margin: 5px 0 10px 0
  max-width: 80%
  padding: 5px
  border-radius: $borderRadius
  color: $whiteColor
  font-weight: normal
  text-transform: none
  letter-spacing: 0
  font-size: 0.8rem
  @include red-gradient()
  strong
    font-weight: normal
  &.full-width
    max-width: 100%

.form-group
  .invalid-feedback
    float: right
    margin: 5px 0
    &:before
      content: ""
      border-left: 5px solid transparent
      border-right: 5px solid transparent
      border-bottom: 5px solid $redPrimaryColor
      border-top: 5px solid transparent
      position: absolute
      top: -10px
      right: 10px
    &.left
      float: left
      &:before
        right: unset
        left: 10px
  &.radio, &.checkbox
    .invalid-feedback
      float: left
      &:before
        right: unset
        left: 10px
  .character-counter
    float: right
    &.invalid
      font-weight: bold
      color: $redPrimaryColor


.form-group-close
  position: relative
  input, select
    width: calc(100% - 48px)
    border-radius: $borderRadius 0 0 $borderRadius !important
  .btn
    color: $textColor
    position: absolute !important

    padding: 0 !important
    text-align: center
    height: 50px !important
    width: 48px !important
    display: inline-block
    border: none
    font-size: 30px
    cursor: pointer
    border-radius:  0 $borderRadius $borderRadius 0 !important
    &.cadastral-btn
      right: 0
      top: 0
    &.files-btn
      right: 0
      bottom: 0

.input-group
  position: relative
  &-no-prepend
    input
      padding-left: 15px !important
  &-no-append
    input
      padding-right: 15px !important
  .loader
    position: absolute
    left: 15px
    margin: auto
    top: 0
    bottom: 0
    right: 15px


.form-checklist
  .form-group
    margin: 0 0 5px

.form-columns
  .row
    padding-right: 7.5px
    padding-left: 7.5px
    min-width: calc(100% + 30px)
    margin-left: -15px
    margin-right: -15px
    >[class*="col"]
      padding-right: 7.5px
      padding-left: 7.5px

.checkbox-small, .radio-small
  margin-left: 35px
  width: 100%
  display: inline-block

/**
 * Responsive
 */
@media (max-width: 576px)
  .form-control
    height: 40px
    padding: 5px 15px

  .checkbox-small, .radio-small
    margin-left: 28px
