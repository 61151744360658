.spacer
  height: 30px
  width: 100%
  float: left
  display: inline-block

.spacer-small
  height: 15px
  width: 100%
  float: left
  display: inline-block

.spacer-gradient
  height: 30px
  width: 100%
  display: inline-block
  position: relative
  &:before
    content: ""
    width: 60px
    height: 4px
    position: absolute
    display: inline-block
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    @include btn-gradient()