.wrapper.empty
  margin-top: -90px
  padding-top: 130px
  padding-bottom: 40px
  background: url('../images/empty-back-banner.png') no-repeat center center transparent
  background-size: cover
  min-height: 874px
  &:after
    content: ""
    height: 40px
    width: 100%
    position: absolute
    display: inline-block
    left: 0
    bottom: 0
    background: rgba(0,0,0,.25)
  .container
    position: relative

  &.logged-in
    overflow: hidden
    &:after
      content: none
    &:before
      content: ""
      height: 100%
      width: 100%
      position: absolute
      display: inline-block
      left: 0
      bottom: 0
      background: url('../images/empty-back-banner-gray.png') no-repeat center center transparent
      background-size: cover
      transform: translateY(90px)
    &.animate
      &:before
        animation: animateHeaderBackground 1.5s alternate

@keyframes animateHeaderBackground
  0%
    opacity: 0
  100%
    opacity: 1

@media(max-width: 576px)
  .wrapper.empty
    min-height: 0
    &:after
      height: 15px

    .card-body
      .alert-inline
        width: 100%
        margin-left: -15px