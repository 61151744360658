.flash-message.alert
  width: 100%
  position: relative
  padding: 15px 15px 15px 45px
  background: white
  box-shadow: 2px 4px 8px rgba(80,80,80,0.1)
  border-radius: 2px
  border: 0
  overflow: hidden
  opacity: 0
  animation-name: AnimateAlert
  animation-delay: 500ms
  animation-duration: 8000ms
  animation-direction: forward
  transform: translateX(100%)
  color: $textColor
  text-align: left
  &.hide-alert
    animation: FadeOutAlert
    animation-duration: 500ms
  &:before
    position: absolute
    left: 15px
    top: calc(50% - 10px)
    height: 20px
    width: 20px
    line-height: 20px
    text-align: center
    font-size: 20px
  &:after
    content: ''
    left: 0
    top: 0
    position: absolute
    height: 100%
    width: 4px
  &.alert-success
    color: $textColor
    &:before
      @include var(color, primary)
    &:after
      @include var(background-color, primary)
  &.alert-warning, &.alert-danger
    color: $textColor
    &:before
      color: $redPrimaryColor
    &:after
      background-color: $redPrimaryColor
  &.alert-information, &.alert-info
    color: $textColor
    &:before
      color: $textColor
    &:after
      background-color: $textColor

.alert-inline
  border-radius: 1px
  border: none
  padding: 10px 25px
  background: $grayLightColor
  margin-bottom: 10px
  display: inline-block
  width: 100%
  position: relative
  dd
    white-space: pre-wrap
  p
    margin: 0 0 5px
    &.pre-line
      white-space: pre-line
  &:after
    content: ""
    width: 6px
    height: 100%
    display: block
    position: absolute
    left: 0
    top: 0
  &[class*="ion-"], &.has-loader
    padding-left: 45px
    position: relative
    .loader
      position: absolute
      top: 0
      bottom: 0
      left: -6px
      margin: auto 0
      transform: scale(0.4)
    &:before
      position: absolute
      top: 0
      bottom: 0
      left: 15px
      width: 15px
      height: 15px
      line-height: 15px
      margin: auto 0
      text-align: center
      font-size: 20px
  &.alert-hint
    margin-top: 5px
    &:before
      color: $orangePrimaryColor
    &:after
      background-color: $orangePrimaryColor
    .loader
      span, &:before, &:after
        border-color: $orangePrimaryColor transparent transparent transparent
  &.alert-warning, &.alert-danger
    color: $textColor
    &:before
      color: $redPrimaryColor
    &:after
      background-color: $redPrimaryColor
    .loader
      span, &:before, &:after
        border-color: $redPrimaryColor transparent transparent transparent
  &.alert-information, &.alert-info
    color: $textColor
    &:before
      color: $orangePrimaryColor
    &:after
      background-color: $orangePrimaryColor
    .loader
      span, &:before, &:after
        border-color: $orangePrimaryColor transparent transparent transparent
  &.alert-success
    color: $textColor
    &:before
      @include var(color, primary)
    &:after
      @include var(background-color, primary)
    .loader
      span, &:before, &:after
        border-color: $textColor transparent transparent transparent
  &.alert-disabled
    color: $textColor
    &:before
      color: $grayDarkColor
    &:after
      background-color: $grayDarkColor
    .loader
      span, &:before, &:after
        border-color: $grayDarkColor transparent transparent transparent
  &.alert-inline-with-image
    padding-left: 125px
    position: relative
    .alert-inline-image
      width: 80px
      height: 80px
      position: absolute
      left: 40px
      top: 0
      bottom: 0
      margin: auto 0
      display: inline-block

.card-body,
.widget-body,
.modal-body
  .alert-inline
    margin-left: -25px
    width: calc(100% + 25px)
  .iterator-group
    .alert-inline
      margin-left: -10px
      width: calc(100% + 10px)
      background: white

main
  &.empty
    .card-body
      .alert-inline
        width: 100%
        margin-left: 0

@keyframes AnimateAlert
  0%
    transform: translateX(100%)
    opacity: 0
  10%
    transform: translateX(0)
    opacity: 1
  90%
    transform: translateX(0)
    opacity: 1
  100%
    transform: translateX(100%)
    opacity: 0
    display: none
    visibility: hidden

@keyframes FadeOutAlert
  0%
    transform: translateX(0)
    opacity: 1
  100%
    transform: translateX(100%)
    opacity: 0
    display: none
    visibility: hidden

.employee-transfer-consider
  margin-top: 45px
