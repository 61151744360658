.homescreen-message
  position: fixed
  opacity: 0
  z-index: -99
  transition: opacity 200ms $animateBezier
  display: block
  &.show
    transition: opacity 200ms $animateBezier
    animation: homescreen 10000ms
    z-index: 99
  &.ios-homescreen-message
    top: 15px
    left: 15px
    right: 15px
    width: calc(100% - 30px)
    max-width: 400px
    border-radius: 15px
    background: $whiteColor
    padding: 20px 20px 20px 55px
    box-shadow: $boxShadowDouble
    margin: auto
    img
      margin-top: -7px
    &:before
      position: absolute
      top: 0
      bottom: 0
      left: 15px
      width: 25px
      height: 25px
      line-height: 25px
      text-align: center
      font-size: 30px
      margin: auto 0
      border-radius: 6px
      background: $textColor
      color: $whiteColor
      opacity: 0.8
    &:after
      content: ""
      border-top: 15px solid transparent
      border-left: 15px solid transparent
      border-bottom: 15px solid $whiteColor
      border-right: 15px solid transparent
      display: block
      position: absolute
      top: -30px
      width: 30px
      height: 30px
      left: 0
      right: 0
      margin: 0 auto
    p
      padding: 0
      margin: 0
      font-size: 16px
    &.is-ipad
      right: 15px
      left: unset
      top: 15px
      bottom: unset
      &:after
        right: 111px
        left: unset
        bottom: unset
        top: -30px


/**
  * Animations
  */
@keyframes homescreen
  0%
    opacity: 0
  10%
    opacity: 1
  90%
    opacity: 1
  100%
    opacity: 0
