.card
  $block: &
  background-color: $whiteColor
  box-shadow: $boxShadow
  border-radius: 3px
  display: flex
  padding: 25px 25px 96px
  border: none
  width: 100%
  position: relative
  min-height: 0
  height: auto
  margin-bottom: 90px
  &-body
    padding: 25px 0
    width: 100%
    flex-direction: column
  &-transparent
    background-color: transparent
    box-shadow: none
    margin-bottom: 0
    padding: 25px
  &-image
    img
      width: 100%
  &-footer
    position: absolute
    bottom: 0
    left: 0
    padding: 20px 25px
    border-radius: 0 0 3px 3px
    margin: 0
    width: 100%
    min-height: 96px
    border-top: 1px solid $grayMediumColor
    .btn
      margin-top: 5px
      margin-bottom: 5px

  &-inline-image
    width: 100%
    display: inline-block
    margin: 20px 0
    img
      width: 100%

@media(max-width: 576px)
  .card
    padding: 15px
    margin-bottom: 30px
    &-body
      padding: 15px 0
    &-footer
      position: relative
      width: calc(100% + 30px)
      margin-left: -15px
      margin-bottom: -15px
    &-transparent
      padding: 0