@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,700')
@import 'base/bootstrap'
@import '~bootstrap/scss/bootstrap'
@import '~bootstrap-select/sass/bootstrap-select'
@import '~ionicons/dist/scss/ionicons'

// Shared components
@import 'base/colors'
@import 'base/include'
@import 'base/base'
@import 'base/headings'

@import 'app/components/row'
@import 'app/components/spacer'

@import 'app/components/checkbox'
@import 'app/components/form'
@import 'app/components/input_group'
@import 'app/components/label'
@import 'app/components/modal_trigger'
@import 'app/components/radio'
@import 'app/components/select'
@import 'app/components/date_picker'
@import 'app/components/homescreen_message'
@import 'app/components/alert'
@import 'app/components/loader'
@import 'app/components/iframe'
@import 'app/components/messages_stack'
@import 'app/components/invoice_details'
@import 'app/components/update_message'

@import 'empty/components/button'
@import 'empty/components/card_header'
@import 'empty/components/card'
@import 'empty/components/card_checks'
@import 'empty/components/card_icon'
@import 'empty/components/card_progress_dots'
@import 'empty/components/form'
@import 'empty/components/nav'
@import 'empty/components/sub_nav'

@import 'empty/layout/wrapper_cta'
@import 'empty/layout/wrapper_empty'
@import 'empty/layout/wrapper_sub'
@import 'empty/layout/wrapper_footer'

body
  background: $textColor





