// Override bootstrap breakpoints and widths
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
) !default;

$container-max-widths: (
    sm: 576px,
    // Original: 540px
    md: 768px,
    // Original: 720px
    lg: 960px,
    xl: 1140px,
) !default;

$grid-gutter-width: 50px !default;

.d-xxl-flex,
.d-xxl-block {
    display: none;
}

@media (min-width: 1400px) {
    .d-xxl-flex {
        display: flex !important;
    }
    .d-xxl-block {
        display: block !important;
    }
    .d-xxl-none {
        display: none !important;
    }

    @for $i from 1 through 12 {
        .col-xxl-#{$i} {
            -webkit-box-flex: 0 !important;
            -ms-flex: 0 0 (calc($i / 12) * 100%) !important;
            flex: 0 0 (calc($i / 12) * 100%) !important;
            max-width: (calc($i / 12) * 100%) !important;
        }
    }
}

@import '~bootstrap/scss/bootstrap';
