.modal-trigger-container
  display: inline-block
  margin-top: -5px
  width: 100%
  float: left
.modal-trigger
  min-height: 40px
  padding: 15px 0 15px 20px!important
  position: relative
  float: left
  cursor: pointer
  color: lighten($textColor, 30) !important
  font-family: $mainFont
  font-size: 11px
  line-height: 11px
  font-weight: 700
  text-transform: uppercase
  letter-spacing: $letterSpacing
  width: auto
  display: inline-block
  &:hover, &:active
    @include var-important(color, primary)
  &:before
    @include var-important(color, primary)
    position: absolute
    top: 2px !important
    left: 0
    font-size: 16px