/**
 * DayPickerInput (default)
 */
input[type="date"]
  height: 50px
  display: inline-block
  float: left
  border-radius: $borderRadius
  border: 2px solid $grayColor
  background-color: $whiteColor
  margin: 0
  padding: 10px 15px 10px 45px
  line-height: 50px
  flex: 1
  color: $textColor
  box-shadow: none
  font-size: $mainFontSize
  &:focus, &:active
    box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.10)
    @include var(border-color, primary)
    outline: none !important

/**
 * Responsive
 */
@media (max-width: 576px)
  input[type="date"]
    height: 40px
    line-height: 40px

/**
 * DayPickerInput (react)
 */

.date-picker
  &__dialog-sheet
    z-index: 9999 !important
    background: white
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.10)
    outline: none !important
    top: 50px !important
    left: 1px !important
.rdp-root // react-day-picker
  --rdp-accent-color: rgba(var(--color-primaryR, $primaryColorR), var(--color-primaryG, $primaryColorG), var(--color-primaryB, $primaryColorB)) !important
  --rdp-accent-background-color: rgba(var(--color-primaryR, $primaryColorR), var(--color-primaryG, $primaryColorG), var(--color-primaryB, $primaryColorB), 0.2) !important

.DayPickerInput
  input
    height: 50px
    width: 100%
    display: inline-block
    float: left
    border-radius: $borderRadius
    border: 2px solid $grayColor
    background-color: $whiteColor
    margin: 0
    padding: 10px 15px 10px 45px
    line-height: 20px
    color: $textColor
    box-shadow: none
    font-size: $mainFontSize
    &:focus, &:active
      box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.10)
      @include var(border-color, primary)
      outline: none !important

.DayPickerInput-OverlayWrapper
  z-index: 9999 !important
  top: 50px !important
.DayPickerInput-Overlay
  z-index: 9999 !important

.DayPicker-Day--selected
  &:not(.DayPicker-Day--disabled)
    &:not(.DayPicker-Day--outside)
      position: relative !important
      @include var-important(background-color, primary)
      color: $whiteColor !important
      &:hover
        @include var-important(background-color, secondary)

.DayPicker
  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day
      &:not(.DayPicker-Day--disabled)
        &:not(.DayPicker-Day--selected)
          &:not(.DayPicker-Day--outside)
            &:hover
              background-color: $grayMediumColor !important


.DayPicker-Day
  border-radius: 3px !important

.DayPicker-Day--today
  color: $textColor !important

/**
 * Pikaday (frontend)
 */
.pika
  $block: &
  &-single
    border-radius: 2px
    border: none
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) !important
  &-lendar
    padding: 0.25em
    table
      thead
        margin-bottom: 1em
      th
        abbr
          padding: 0.5em
          min-width: 1em
          color: #8B9898
          font-weight: normal
          vertical-align: middle
          text-align: right
          font-size: 1em
          cursor: pointer
      td
        background: transparent
        padding: 0
        text-align: left
        #{ $block }
          &-button
            background: none
            padding: 0.75em 0.25em
            border-radius: 3px
            vertical-align: middle
            text-align: center
            cursor: pointer
            font-family: $mainFont
            font-weight: 500
            font-size: 1.15em
            box-shadow: none !important
            &:hover, &:active
              background: $grayColor
              color: $blackColor

        &.is-selected
          #{ $block }
            &-button
              @include var(background-color, primary)
              color: $whiteColor
              &:hover, &:active
                @include var(background-color, secondary)
        &.is-today
          #{ $block }
            &-button
              border: none
              background-color: transparent
              background-image: none
              box-shadow: none
              @include var(color, primary)
              cursor: pointer
