.card-progress-dots
  position: fixed
  right: 0
  top: 0
  bottom: 0
  margin: auto 0
  height: auto
  width: 30px
  padding: 0
  text-align: center
  display: inline-block
  &-3
    height: 150px
  &-4
    height: 200px
  span
    width: 10px
    height: 10px
    display: inline-block
    margin: 20px 10px
    position: relative
    float: left
    text-shadow: 0 1px 2px rgba(0,0,0,0.1)
    .circle
      width: 10px
      height: 10px
      margin: 0
      display: inline-block
      background: $grayColor
      border-radius: 50%
      float: left
    small
      white-space: nowrap
      text-align: right
      padding: 0 20px
      position: absolute
      display: inline-block
      right: 0
      top: 0
      bottom: 0
      margin: auto 0
      height: 30px
      line-height: 30px
      text-transform: uppercase
      font-weight: 700
      letter-spacing: $letterSpacingLarge
      color: $mutedTextColor
      &:before
        position: absolute
        width: 12px
        height: 12px
        text-align: center
        top: 0
        bottom: 0
        left: 0
        margin: auto 0
    &.past
      .circle
        background: $textColor
      small
        &:before
          @include var(color, primary)
    &.selected
      .circle
        @include var(background, primary)
        transform: scale(1)
        animation: grow 1500ms
        animation-iteration-count: 3
      small
        color: $textColor

@keyframes grow
  0%
    transform: scale(1)
  50%
    transform: scale(1.3)
  100%
    transform: scale(1)

.wrapper.empty:not(.logged-in)
  .card-progress-dots
    small
      color: $publicDark
    .circle
      background: white

@media(max-width: 1550px)
  .card-progress-dots
    small
      font-size: 11px

@media(max-width: 1400px)
  .card-progress-dots
    small
      display: none !important

@media(max-width: 576px)
  .card-progress-dots
    display: none