h1
  @include var(color, primary)
  font-family: $mainFont
  font-size: $h1FontSize
  line-height: $h1LineHeight
  font-weight: 700
  text-transform: uppercase
  margin: 15px 0 5px
  letter-spacing: $letterSpacingLarge
  width: 100%
  display: inline-block

h2
  color: $textColor
  font-family: $mainFont
  font-size: $h2FontSize
  line-height: $h2LineHeight
  font-weight: 700
  text-transform: uppercase
  margin: 15px 0 5px
  letter-spacing: $letterSpacing
  width: 100%
  display: inline-block
  form
    *
      display: inline-block
      float: left
    [class*="ion"]
      height: 15px
      margin: 4px 2px 0 0

h3
  color: $textColor
  font-family: $mainFont
  font-size: $h3FontSize
  line-height: $h3LineHeight
  font-weight: 700
  text-transform: uppercase
  margin: 15px 0 5px
  letter-spacing: $letterSpacing
  width: 100%
  display: inline-block

h1, h2, h3
  .thin
    font-weight: 100
  a[class*="ion-"]
    margin-left: 5px
  &.colored
    @include var(color, primary)

.heading-loader
  position: relative
  .loader-small
    position: absolute
    right: -15px
    top: -8px
    bottom: 0
    margin: auto

/**
 * Responsive
 */
@media only screen and (max-width: 576px)
  h1
    font-size: round($h1FontSize * 0.6)
    line-height: round($h1LineHeight * 0.6)
  h2
    font-size: round($h2FontSize * 0.8)
    line-height: round($h2LineHeight * 0.8)
  h3
    font-size: round($h3FontSize * 0.8)
    line-height: round($h3LineHeight * 0.8)