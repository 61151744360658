.card-icon
  $block: &
  &-container
    padding: 30px 0
    width: 100%
    flex-direction: column
    text-align: center
    #{ $block }
      width: 100px
      height: 100px
      display: inline-block
      border-radius: 50%
      @include var(background, primary)
      color: $whiteColor
      font-size: 60px
      line-height: 100px
      text-align: center
      &-green
        @include var(background, primary)
      &-orange
        background: $orangePrimaryColor
      &-red
        background: $redPrimaryColor