.sub-nav
  width: 100%
  height: 40px
  background: white
  padding: 0 15px
  text-align: center
  ul
    float: right
    display: inline-block
    list-style-type: none
    margin: 0
    padding: 0
    li
      padding: 9px 0 9px 20px
      float: left
      a
        color: $textColor
        &:hover, &:active
          color: $textColor
          text-decoration: underline