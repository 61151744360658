body
  font-family: $mainFont
  background: $backgroundColor
  color: $textColor
  font-size: $mainFontSize
  min-height: 100%
  width: 100%
  position: absolute
  -webkit-font-smoothing: antialiased
  -moz-font-smoothing: antialiased
  font-smoothing: antialiased

main
  min-height: calc(100% - 100px)
  position: absolute !important
  width: 100%

.wrapper
  display: flex
  display: -webkit-box
  display: -ms-flexbox
  position: relative

a
  @include var(color, primary)
  transition: color 100ms $animateBezier
  cursor: pointer
  word-break: break-all
  &:hover
    @include var(color, primary-darken)
    transition: color 100ms $animateBezier
    text-decoration: none
  &:not(.btn)
    &[class*="ion-"]
      position: relative
      padding-left: 20px
      text-decoration: none
      &:before
        position: absolute
        top: 0 !important
        bottom: 0
        left: 0
        width: 15px
        height: 15px
        line-height: 15px
        margin: auto 0
        text-align: center
        font-size: 20px
      &[class*="back"],
      &[class*="previous"]
        &:before
          transition: transform 100ms $animateBezier
        &:hover
          &:before
            transition: transform 100ms $animateBezier
            transform: translateX(-3px)
      &[class*="forward"],
      &[class*="next"]
        padding-left: 0
        padding-right: 20px
        &:before
          transition: transform 100ms $animateBezier
        &:hover
          &:before
            transition: transform 100ms $animateBezier
            transform: translateX(3px)
        &:before
          left: inherit
          right: 0

p.leading
  font-size: 17px

p, small, span
  &.thin
    font-weight: 100
  &.text-secondary
    color: lighten($textColor, 40) !important
    &:before
      @include var(color, primary)
  &.thin
    font-weight: 100

small
  font-size: $smallFontSize
  &.vat
    color: lighten($textColor, 30)
    text-transform: uppercase
    font-size: 10px

.uppercase
  text-transform: uppercase

.text-muted
  color: $mutedTextColor !important

.text-red
  color: $redPrimaryColor !important

.text-green
  @include var-important(color, primary)

.text-line-through
  text-decoration: line-through

[class*="ion-"]
  &:focus
    outline: none
  &.active:before
    @include var(color, primary)

.disabled
  opacity: 0.5

.hidden
  display: none

#animation-fireworks
  position: fixed
  top: 0
  left: 0
  z-index: 999
  height: 100%
  width: 100%
  pointer-events: none

/**
 * Responsive
 */
@media (max-width: 576px)
  body
    font-size: 13px
  main
    min-height: calc(100% - 60px)
  p.leading
    font-size: 15px

.appraisal-header
  height: 52px
  display: flex
  align-items: center
  a
    font-size: 17px
  .card-header-content
    display: flex
    flex: 1
    justify-content: flex-start
    margin-left: 10px
  .card-header-options
    right: 20px
    top: 5px
