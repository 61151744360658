.card-checks
  margin: 0
  padding: 0
  li
    list-style-type: none
    position: relative
    padding: 0 0 20px 45px

    &:before
      display: inline-block
      font-size: 35px
      line-height: 25px
      color: white
      position: absolute
      top: 0
      left: 0
      height: 25px
      width: 35px
      text-align: center
    *
      text-shadow: 0 1px 2px rgba(0,0,0,0.1)
    h1, h2, h3
      color: white
      font-size: 22px
      line-height: 24px
      margin: 0 0 15px
    p
      color: $publicDark
      font-size: 18px
      line-height: 24px
