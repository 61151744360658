.row
  width: 100%
  min-width: calc(100% + 50px)
  -webkit-box-flex: 1
  -ms-flex: 1
  flex: 1
  &-wide
    margin: 0
    min-width: 100%
    .col-wide
      padding: 0