label
  color: lighten($textColor, 30)
  font-family: $mainFont
  font-size: 11px
  font-weight: 700
  text-transform: uppercase
  margin: 15px 0 5px
  letter-spacing: $letterSpacing
  width: auto
  display: inline-block
  .form-label-highlight
    height: 11px
    line-height: 11px
  &.validation-focused
    color: $redPrimaryColor
    .form-label-highlight
      color: $redPrimaryColor
      transform: scale(1)
      animation: grow 1000ms
      animation-iteration-count: 5

  &.label-placeholder
    opacity: 0.4
    width: 60%
    height: 16px
    border-radius: $borderRadius
    background: $grayColor
    display: flex

.label-container
  margin: 15px 0 5px
  display: inline-flex
  align-items: center
  .history-button
    padding: 15px 0
    margin: -15px 0
    width: 20px
    color: lighten($textColor, 30)
    transform: scale(0.7)
    transition: transform $animateBezier 0.2s
    &:hover:not(:disabled), &:focus:not(:disabled)
      transform: scale(1)
      transition: transform $animateBezier 0.2s
  label
    margin: 0

/**
  * Animations
  */
@keyframes grow
  0%
    transform: scale(1)
  50%
    transform: scale(1.5)
  100%
    transform: scale(1)
