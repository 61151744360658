.nav
  height: 90px
  width: 100%
  background: rgba(0,0,0,.25)
  position: relative
  z-index: 1
  padding: 0 15px
  ul
    float: right
    display: inline-block
    list-style-type: none
    margin: 0 10px 0 0
    padding: 0
    li
      display: inline-block
      padding: 0
      white-space: nowrap
      a
        display: inline-block
        line-height: 60px
        font-size: 18px
        padding: 15px 20px
        color: white
        &:hover, &:active
          color: white
          text-decoration: underline
  &-buttons
    float: right
    padding: 22px 0
    .btn
      float: left
      margin: 0 0 0 15px
      &-secondary
        background: transparent
        border: 2px solid white
        &:hover, &:active, &:focus
          background: white !important
          @include var-important(color, primary)
          border-color: white !important
    &-primary
      border: 2px solid white
      &:hover, &:active, &:focus
        border-color: white

  &-logo
    height: 90px
    padding: 15px 0
    img
      height: 100%

@media(max-width: 576px)
  .nav
    &-logo
      padding: 25px 0