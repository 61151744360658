.card-header
  font-size: $h1FontSize
  line-height: $h1LineHeight
  background: none
  border: none
  position: relative
  @include var(color, primary)
  letter-spacing: $letterSpacingLarge
  font-weight: 700
  text-transform: uppercase
  padding: 20px 0
  h1, h2
    margin: 0
    padding: 0