select
  &.form-control
    &.custom-select
      height: 50px !important
      -webkit-appearance: none
      -moz-appearance: none
      -ms-appearance: none
      -o-appearance: none
      appearance: none
      &-sm
        height: 40px !important
        padding: 5px 15px !important
      &.required-not-selected
        border: 2px solid $redPrimaryColor
    &:disabled
      cursor: not-allowed
      background-color: $grayMediumColor

.custom-select
  &-placeholder
    display: flex
    align-items: center
    pointer-events: none
    background: transparent !important
    &-text
      opacity: 0.4
      width: 60%
      height: 15px
      border-radius: $borderRadius
      background: $grayColor
      display: flex

.select-container
  height: 50px
  width: 100%

.bootstrap-select
  width: 100%
  button.dropdown-toggle
    height: 50px
    background: $whiteColor
    border-radius: 3px
    border: 2px solid $grayColor
    padding: 10px 15px
    line-height: 20px
    color: $textColor !important
    text-transform: none
    box-shadow: none
    font-weight: 400
    letter-spacing: 0
    font-size: $mainFontSize
    &:focus, &:active, &:hover
      background: $whiteColor !important
      color: $textColor !important
      outline: none !important
      box-shadow: none !important
      transform: translateY(0)
  &.show
    button
      box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.10)
      @include var(border-color, primary)
      outline: none !important
  &.disabled
    pointer-events: none

  &.small
    padding: 0
    button.dropdown-toggle
      height: 40px
      padding: 7px 15px

  &.small-text
    button.dropdown-toggle
      font-size: 13px

  .dropdown-toggle
    &:after
      top: 0
      right: 15px
      bottom: 0
      position: absolute
      margin: auto 0
      width: 1px
      height: 1px
    .filter-option
      img
        margin-right: 10px

  .dropdown-menu
    width: 100%
    &.inner
      max-height: 180px !important
    li
      float: left
      width: 100%
    .dropdown-item
      padding: 10px 15px
      outline: none !important
      box-shadow: none !important
      .check-mark
        position: absolute
        right: 5px
        top: 0
        bottom: 0
        height: 20px
        width: 20px
        font-size: 20px
        line-height: 20px
        text-align: center
        margin: auto 0
        color: $whiteColor
      img
        margin-right: 10px
      &.dropdown-header
        padding: 10px 15px
        text-transform: uppercase
        font-size: 11px
        letter-spacing: $letterSpacing
        color: lighten($textColor, 30)
        font-weight: 700
        pointer-events: none
      &.divider
        height: 1px
        padding: 0
        background: $grayColor
      &.selected
        color: $textColor
        @include var(background, primary)
        font-weight: 700
        .check-mark
          display: inline-block !important
      &.active
        color: $textColor
        background: $grayMediumColor
        font-weight: 700
      &:hover, &:focus, &:active
        background: $grayColor
        color: $textColor
        outline: none !important
        box-shadow: none !important


/**
 * Responsive
 */
@media (max-width: 576px)
  select
    &.form-control
      &.custom-select
        height: 40px !important