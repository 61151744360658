.wrapper.empty
  .form-control
    border-radius: 5px

  .input-group
    .input-group-prepend
      .input-group-text
        border-radius: 5px 0 0 5px
    .input-group-append
      .input-group-text
        border-radius: 5px 0 0 5px

    > .form-control:not(:first-child),
    > .custom-select:not(:first-child)
      border-top-left-radius: 5px
      border-bottom-left-radius: 5px