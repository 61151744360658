.update-message
  position: fixed
  z-index: 9999
  display: block
  top: 15px
  left: 15px
  right: 15px
  width: calc(100% - 30px)
  max-width: 400px
  background: $whiteColor
  padding: 20px 20px 20px 55px
  box-shadow: $boxShadowDouble
  margin: auto
  &.ios-update-message
    border-radius: 15px
  &:before
    position: absolute
    top: 0
    bottom: 0
    left: 15px
    width: 25px
    height: 25px
    line-height: 25px
    text-align: center
    font-size: 18px
    margin: auto 0
    border-radius: 6px
    background: $textColor
    color: $whiteColor
    opacity: 0.8
  p
    padding: 0
    margin: 0
    font-size: 16px
  button
    background: none
    border: none
    padding: 0
    cursor: pointer