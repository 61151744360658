.messages-stack
  width: 320px
  max-width: calc(100% - 30px)
  z-index: 21000
  position: fixed
  top: 75px
  right: 15px
  &.no-pointer
    pointer-events: none
  >*
      margin-top: 10px