.wrapper.sub
  background: $publicDark
  color: white
  padding: 50px 0
  border-bottom: 2px solid $textColor
  p
    font-size: 18px
    line-height: 22px
    color: white
    margin: 0 0 15px
  h2
    color: white
    font-size: 36px
    line-height: 40px
    margin: 0 0 20px