/**
 * Fonts
 */
$mainFont: "Barlow", Arial, Tahoma, sans-serif
$mainFontSize: 16px
$letterSpacing: 1px
$letterSpacingLarge: 2px
$smallFontSize: 13px

$animateBezier: cubic-bezier(0.5, 0, 0.25, 1) // Instead of $animateBezier etc

$h1FontSize: 36px
$h1LineHeight: 40px
$h2FontSize: 17px
$h2LineHeight: 22px
$h3FontSize: 14px
$h3LineHeight: 18px

/**
 * Other
 */
$boxShadow: 0 10px 30px rgba(51, 51, 51, 0.10)
$boxShadowOverlay: 0 0px 30px rgba(51, 51, 51, 0.10)
$boxShadowDouble: 0 10px 30px rgba(51, 51, 51, 0.20)
$boxShadowSecondary: 0 10px 30px rgba(51, 51, 51, 0.05)
$borderRadius: 3px

@mixin btn-gradient()
  @include var(background, primary)
  background: -moz-linear-gradient(210deg, var(--color-secondary) 0%, var(--color-primary) 100%)
  background: -webkit-linear-gradient(210deg, var(--color-secondary) 0%, var(--color-primary) 100%)
  background: linear-gradient(210deg, var(--color-secondary) 0%, var(--color-primary) 100%)

@mixin btn-gradient-inactive()
  background: var(--color-primary-lighten)
  background: -moz-linear-gradient(210deg, var(--color-secondary-lighten) 0%, var(--color-primary-lighten) 100%)
  background: -webkit-linear-gradient(210deg, var(--color-secondary-lighten) 0%, var(--color-primary-lighten) 100%)
  background: linear-gradient(210deg, var(--color-secondary-lighten) 0%, var(--color-primary-lighten) 100%)

@mixin btn-gradient-hover()
  background: var(--color-primary-darken) !important
  background: -moz-linear-gradient(210deg, var(--color-secondary-darken) 0%, var(--color-primary-darken) 100%) !important
  background: -webkit-linear-gradient(210deg, var(--color-secondary-darken) 0%, var(--color-primary-darken) 100%) !important
  background: linear-gradient(210deg, var(--color-secondary-darken) 0%, var(--color-primary-darken) 100%) !important

@mixin orange-gradient()
  background: $redPrimaryColor
  background: -moz-linear-gradient(210deg, $orangeSecondaryColor 0%, $orangePrimaryColor 100%)
  background: -webkit-linear-gradient(210deg, $orangeSecondaryColor 0%, $orangePrimaryColor 100%)
  background: linear-gradient(210deg, $orangeSecondaryColor 0%, $orangePrimaryColor 100%)

@mixin orange-gradient-hover()
  background: darken($orangePrimaryColor, 5%) !important
  background: -moz-linear-gradient(210deg, darken($orangeSecondaryColor, 5%) 0%, darken($orangePrimaryColor, 5%) 100%) !important
  background: -webkit-linear-gradient(210deg, darken($orangeSecondaryColor, 5%) 0%, darken($orangePrimaryColor, 5%) 100%) !important
  background: linear-gradient(210deg, darken($orangeSecondaryColor, 5%) 0%, darken($orangePrimaryColor, 5%) 100%) !important

@mixin red-gradient()
  background: $redPrimaryColor
  background: -moz-linear-gradient(210deg, $redSecondaryColor 0%, $redPrimaryColor 100%)
  background: -webkit-linear-gradient(210deg, $redSecondaryColor 0%, $redPrimaryColor 100%)
  background: linear-gradient(210deg, $redSecondaryColor 0%, $redPrimaryColor 100%)

@mixin red-gradient-hover()
  background: darken($redPrimaryColor, 5%) !important
  background: -moz-linear-gradient(210deg, darken($redSecondaryColor, 5%) 0%, darken($redPrimaryColor, 5%) 100%) !important
  background: -webkit-linear-gradient(210deg, darken($redSecondaryColor, 5%) 0%, darken($redPrimaryColor, 5%) 100%) !important
  background: linear-gradient(210deg, darken($redSecondaryColor, 5%) 0%, darken($redPrimaryColor, 5%) 100%) !important

@mixin green-gradient()
  background: $greenPrimaryColor
  background: -moz-linear-gradient(210deg, $greenSecondaryColor 0%, $greenPrimaryColor 100%)
  background: -webkit-linear-gradient(210deg, $greenSecondaryColor 0%, $greenPrimaryColor 100%)
  background: linear-gradient(210deg, $greenSecondaryColor 0%, $greenPrimaryColor 100%)

@mixin green-gradient-hover()
  background: darken($greenPrimaryColor, 5%) !important
  background: -moz-linear-gradient(210deg, darken($greenSecondaryColor, 5%) 0%, darken($greenPrimaryColor, 5%) 100%) !important
  background: -webkit-linear-gradient(210deg, darken($greenSecondaryColor, 5%) 0%, darken($greenPrimaryColor, 5%) 100%) !important
  background: linear-gradient(210deg, darken($greenSecondaryColor, 5%) 0%, darken($greenPrimaryColor, 5%) 100%) !important

@mixin vertical-line-gradient()
  background: $grayColor
  background: -moz-linear-gradient(top, $whiteColor 0%, $grayColor 8%, $grayColor 92%, $whiteColor 100%)
  background: -webkit-linear-gradient(top, $whiteColor 0%, $grayColor 8%, $grayColor 92%, $whiteColor 100%)
  background: linear-gradient(to bottom, $whiteColor 0%, $grayColor 8%, $grayColor 92%, $whiteColor 100%)

@mixin gold-gradient()
  background: $goldColor
  background: -moz-linear-gradient(210deg, $goldColor 0%, $goldSecondaryColor 100%)
  background: -webkit-linear-gradient(210deg, $goldColor 0%, $goldSecondaryColor 100%)
  background: linear-gradient(210deg, $goldColor 0%, $goldSecondaryColor 100%)

@mixin gradient($deg, $fromColor, $toColor)
  background: $fromColor
  background: -moz-linear-gradient($deg, $fromColor 0%, $toColor 100%)
  background: -webkit-linear-gradient($deg, $fromColor 0%, $toColor 100%)
  background: linear-gradient($deg, $fromColor 0%, $toColor 100%)

