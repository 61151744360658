.btn
  display: inline-block
  padding: 11px 20px
  line-height: 21px
  position: relative
  box-sizing: border-box
  border-radius: 5px
  font-size: 18px
  border: none
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2)
  word-break: break-word
  &:hover, &:active, &:focus
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important
    outline: 0
  &-primary
    padding: 12px 20px
    background: $orangePrimaryColor
    border-bottom: 2px solid $orangeSecondaryColor
    color: white
    &:disabled, &.disabled
      background: $orangePrimaryColor
      border-bottom: 2px solid $orangeSecondaryColor
      opacity: 0.5
      color: white !important
    &:hover, &:active, &:focus
      background: $orangeSecondaryColor !important
      border-color: darken($orangeSecondaryColor, 20) !important
  &-secondary
    @include var(background, primary)
    color: white
    border-bottom: 2px solid
    @include var(border-bottom-color, secondary)
    &:disabled, &.disabled
      @include var(background, primary)
      color: white !important
      border-bottom: 2px solid
      @include var(border-bottom-color, secondary)
      opacity: 0.5
    &:hover, &:active, &:focus
      @include var-important(background, secondary)
      @include var-important(border-color, secondary-darken)
  &-link
    padding: 11px 0
    background: transparent
    color: $textColor
    border: none
    box-shadow: none !important
    text-align: left
    &:disabled, &.disabled
      background: transparent
      color: $textColor
      border: none
      opacity: 0.5
    &:hover, &:active, &:focus
      background: transparent
      @include var-important(color, primary)
      box-shadow: none !important
  &[class*="ion-"]
    position: relative
    padding-left: 35px
    &:before
      position: absolute
      top: 0
      bottom: 0
      left: 15px
      width: 15px
      height: 15px
      line-height: 15px
      margin: auto 0
      text-align: center
      font-size: 20px
    &[class*="forward"],
    &[class*="next"],
    &[class*="checkbox-outline"]
      padding-left: 20px
      padding-right: 35px
      &:before
        left: auto
        right: 15px