.is-form-hidden
  display: none

.input-group
  position: relative
  .input-group-prepend
    position: absolute
    top: 2px
    left: 2px
    height: calc(100% - 4px)
    z-index: 100
    .input-group-text
      border: none
      border-radius: 1px 0 0 1px
      background: transparent
      @include var(color, primary)
      font-size: 18px
      padding: 0 15px
    .input-group-color
      width: 23px
      height: 23px
      display: inline-block
      position: absolute
      top: 0
      left: 10px
      right: 0
      bottom: 0
      margin: auto
      border-radius: 50%
      border: 2px solid $grayColor
  .input-group-append
    position: absolute
    top: 2px
    right: 2px
    height: calc(100% - 4px)
    z-index: 100
    .btn-append
      width: 45px
      height: 100%
      position: absolute
      top: 0
      right: 0
      border-radius: 1px
    .input-group-text
      border: none
      border-radius: 1px 0 0 1px
      background: transparent
      color: lighten($textColor, 30)
      font-size: $mainFontSize
      padding: 0 15px
  &.append-btn
    input
      padding-right: 60px
      border-radius: $borderRadius !important
  &.append
    input
      padding-right: 80px
      border-radius: $borderRadius !important
  input
    padding-left: 45px
    border-radius: $borderRadius !important
  input.small
    padding-left: 45px

.input-group-prepend
  &.is-invalid
    .input-group-text
      color: $redPrimaryColor