input[type='radio']
  display: none
  ~ label
    position: relative
    padding-left: 35px
    text-transform: none
    cursor: pointer
    line-height: 32px
    color: $textColor
    box-shadow: none
    font-size: $mainFontSize
    font-weight: 400
    letter-spacing: 0
    margin: 5px 0
    &:before
      position: absolute
      left: 0
      content: ""
      border: 2px solid
      @include var(border-color, primary)
      border-radius: 14px
      width: 28px
      height: 28px
      background-color: $whiteColor
      text-align: center
      line-height: 22px
      font-size: 10px
      top: 3px
      transition: border 200ms $animateBezier
    &:after
      // To show highlighting
      position: absolute
      left: -2px
      content: ""
      border: 2px solid transparent
      border-radius: 16px
      width: 32px
      height: 32px
      text-align: center
      line-height: 26px
      font-size: 10px
      top: 1px
  &:checked
    ~ label
      &:before
        border: 9px solid
        @include var(border-color, primary)
        transition: border 200ms $animateBezier
  &:disabled
    + label
      cursor: not-allowed
      &:before
        border-color: $grayMediumColor

/**
 * Responsive
 */
@media (max-width: 576px)
  input[type='radio']
    + label
      padding-left: 28px
      line-height: 24px
      &:before
        width: 22px
        height: 22px
    &:checked
      + label
        &:before
          border: 7px solid
          @include var(border-color, primary)
