$textColor: #5F5F68
$mutedTextColor: #878790
$blackColor: #000000
$whiteColor: #FFFFFF
$primaryColor: #32EDDA
$secondaryColor: #26D6DD
$primaryColorR: 50
$primaryColorG: 237
$primaryColorB: 218
$backgroundColor: #FAFAFA
$grayColor: #e2e4ea
$grayLightColor: $backgroundColor
$grayMediumColor: #E9EAEE
$grayDarkColor: #a4a6ac
$redPrimaryColor: #fd7284
$redSecondaryColor: #F65A8A
$errorBackgroundColor: rgb(255, 237, 237)
$greenPrimaryColor: #58c17b
$greenSecondaryColor: #44ab66
$orangePrimaryColor: #f89737
$orangeSecondaryColor: #dd7e36
$publicDark: #424248
$bronzeColor: #E5CEAF
$bronzeSecondaryColor: #A0886C
$silverColor: #EFEFEF
$silverSecondaryColor: #D8D8D8
$goldColor: #facc41
$goldSecondaryColor: #E6AE41
$platinumColor: #8E8A89
$platinumSecondaryColor: #453F3F
$badgeMuchBetter: #52a75a
$badgeBetter: #99c45f
$badgeNeutral: #f5f453
$badgeWorse: #ebbb42
$badgeMuchWorse: #de3626
$automationFilledColor: $goldColor
$automationFilledBackground: #facc4133
$automationFilledBorder: #f8e9bd

$colors: ()
$colors: map-merge($colors, (primary: $primaryColor))
$colors: map-merge($colors, (primaryR: $primaryColorR))
$colors: map-merge($colors, (primaryG: $primaryColorG))
$colors: map-merge($colors, (primaryB: $primaryColorB))
$colors: map-merge($colors, (primary-darken: #1BEBD6))
$colors: map-merge($colors, (primary-lighten: #87fbff))
$colors: map-merge($colors, (secondary: #26D6DD))
$colors: map-merge($colors, (secondary-darken: #20C3CA))
$colors: map-merge($colors, (secondary-lighten: #8dfff3))
$colors: map-merge($colors, (primary-navbar: #20b5ba))
$colors: map-merge($colors, (secondary-navbar: #32d6dc))

:root
  @each $name, $color in $colors
    --color-#{"" + $name}: #{$color}

@function map-get-strict($map, $key)
  @if map-has-key($map, $key)
    @return map-get($map, $key)
  @else
    @error "ERROR: Specified index " + $key + " does not exist in the map"

@mixin var($property, $varName)
  #{$property}: map-get-strict($colors, $varName)
  #{$property}: var(--color-#{$varName})

@mixin var-important($property, $varName)
  #{$property}: map-get-strict($colors, $varName) !important
  #{$property}: var(--color-#{$varName}) !important