.question-loader
  opacity: 0
  animation-name: fadeIn
  animation-duration: 600ms
  animation-timing-function: ease
  animation-iteration-count: 1
  animation-delay: 400ms
  .loader
    animation-delay: 400ms

@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
  
.loader
  $block: &
  display: inline-block
  position: relative
  width: 74px
  height: 74px
  &-icon
    position: absolute
    left: 0
    top: 0
    height: 100%
    width: 100%
    text-align: center
    line-height: 74px
    font-size: 30px
    color: $grayDarkColor
    animation: opacityLoaderIcon 2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    &.c-icon
      &:before
        background: $grayDarkColor
  span:not(.loader-icon), &:before, &:after
    content: ""
    box-sizing: border-box
    display: block
    position: absolute
    width: 74px
    height: 74px
    margin: 0
    border: 4px solid transparent
    border-radius: 50%
    animation: load 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite
    @include var(border-top-color, primary)

  span
    animation-delay: -0.45s
  &:before
    animation-delay: -0.3s
  &:after
    animation-delay: -0.15s

  &-small
    transform: scale(0.4)
    span, &:before, &:after
      border-color: transparent
      border-top-color: $grayDarkColor

  &-logo-container
    margin-top: 40px
    position: relative
    #{ $block }
      width: 120px
      height: 120px
      span, &:before, &:after
        width: 120px
        height: 120px
        z-index: 1
      &-logos
        position: absolute
        width: 120px
        height: 120px
        top: 0
        bottom: 0
        left: 0
        right: 0
        margin: auto
        img
          position: absolute
          width: 65%
          top: 0
          bottom: 0
          left: 0
          right: 0
          margin: auto
          opacity: 0
          &.loader-logo-full-size
            width: 110px
            height: 110px
            object-fit: cover
            border-radius: 50%
            margin: 5px
        &.loader-3
          img
            animation: loopImage3 3s cubic-bezier(0.5, 0, 0.5, 1) infinite
            @for $i from 1 through 3
              &:nth-child(#{$i})
                animation-delay: $i - 1s
        &.loader-6
          img
            animation: loopImage6 6s cubic-bezier(0.5, 0, 0.5, 1) infinite
            @for $i from 1 through 6
              &:nth-child(#{$i})
                animation-delay: $i - 1s
        &.loader-fade-in-out
          img
            transition: opacity 0.5s ease-in-out
            opacity: 1
            animation: loopFadeIn 1s cubic-bezier(0.5, 0, 0.5, 1)
            &.loader-fade-out
              opacity: 0
              animation: none
  &-titles
    position: relative
    width: 100%
    height: 20px
    margin-bottom: 15px
    &.loader-6
      small
        position: absolute
        left: 0
        top: 0
        bottom: 0
        right: 0
        text-align: center
        font-weight: 600
        opacity: 0
        color: $textColor
        animation: loopImage6 6s cubic-bezier(0.5, 0, 0.5, 1) infinite
        @for $i from 1 through 10
          &:nth-child(#{$i})
            animation-delay: $i - 1s

  &-progress-overlay
    width: 100%
    height: 100%
    border-radius: 50%
    overflow: hidden
    display: block
    position: absolute
    bottom: 0
    top: 0
    right: 0
    left: 0
    margin: auto
    div
      display: block
      position: absolute
      height: 100%
      bottom: 0
      left: 0
      @include var(background, primary)
      opacity: 0.2

  &-overflow-gradient
    content: " "
    position: absolute
    width: 100%
    height: 250px
    bottom: 0
    left: 0
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(255,255,255,255) 100%)

.btn,
button
  .loader
    transform: scale(.5)
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    margin: auto
    span, &:before, &:after
      border-color: $textColor transparent transparent transparent
  &.btn-primary
    .loader
      span, &:before, &:after
        border-color: $whiteColor transparent transparent transparent
  &-text-link
    position: relative
    .loader
      transform: scale(.25)
      position: absolute
      top: 0
      right: -50px
      bottom: 0
      margin: auto 0
      span, &:before, &:after
        border-color: $textColor transparent transparent transparent
.btn-sm
  &.has-loader
    padding-left: 45px


@keyframes opacityLoaderIcon
  0%
    opacity: 1
  50%
    opacity: 0.6
  100%
    opacity: 1

@keyframes load
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)

@keyframes loopImage3
  0%
    opacity: 0
  25%
    opacity: 1
  50%
    opacity: 0
  100%
    opacity: 0

@keyframes loopImage6
  0%
    opacity: 0
  14%
    opacity: 1
  28%
    opacity: 0
  100%
    opacity: 0


@keyframes loopFadeIn
  0%
    opacity: 0
  100%
    opacity: 1


