input[type='checkbox']
  display: none
  ~ label
    position: relative
    padding-left: 35px
    padding-top: 5px
    text-transform: none
    cursor: pointer
    line-height: 1.5
    color: $textColor
    box-shadow: none
    font-size: $mainFontSize
    font-weight: 400
    letter-spacing: 0
    margin: 5px 0
    min-width: 28px
    min-height: 28px
    &:before
      position: absolute
      left: 0
      content: ""
      border: 2px solid $grayColor
      border-radius: 3px
      width: 28px
      height: 28px
      background-color: $whiteColor
      text-align: center
      line-height: 22px
      font-size: 10px
      top: 3px
    &:after
      position: absolute
      content: ""
      left: 9px
      top: 6px
      bottom: 0
      right: 0
  &:checked
    ~ label
      &:after
        width: 10px
        height: 18px
        border: solid
        @include var(border-color, primary)
        border-width: 0 4px 4px 0
        transform: rotate(45deg)
  &:disabled
    + label
      cursor: not-allowed
      &:after
        border-color: $grayMediumColor


/**
 * Custom alignment
 */
input[type='checkbox']
  ~ label
    &.label-align-right
      padding-right: 35px
      padding-left: 0
      &:before
        left: inherit
        right: 0
      &:after
        left: inherit
        right: 9px

/**
 * Responsive
 */
@media (max-width: 576px)
  input[type='checkbox']
    ~ label
      padding-left: 28px
      line-height: 24px
      &:before
        width: 22px
        height: 22px
      &.label-align-right
        &:before
          left: unset !important
          right: 0 !important
        &:after
          left: unset !important
          right: 7px !important
    &:checked
      ~ label
        &:after
          left: 7px
          top: 4px
          width: 9px
          height: 16px
          border-width: 0 3px 3px 0